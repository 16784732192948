h1 {
  color: #99e1d9;
  margin-bottom: 0;
  text-align: center;
}

h2 {
  color: #ffffff;
  margin-bottom: 60px;
  text-align: center;
}

h3 {
  text-align: center;
  margin-top: 0;
  font-size: 30px;
}

form {
  text-align: center;
}

p {
  margin-top: 0;
}

.contactPage {
  position : relative;
  display: flex;
  flex-direction: column;
  border: 0px #ddd;
  border-radius: 3px;
  color: #ffffff;
  padding: 20px;
  width: 500px;
  max-height: 900px;
  margin: 0 auto;
  background: linear-gradient(180deg, #ffffff80 0%, #413f3f 100%);
  box-shadow: rgba(172, 177, 176, 0.378) 0px 48px 100px 0px;
}

.names {
  display: flex;
  justify-content: space-between;
}

label {
  margin-bottom: 5px;
  font-size: 16px;
  color: #ffffff;
}

input[type="text"],
input[type="email"],
textarea {
  display: block;
  padding: 0;
  margin: 20px auto;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  color: #444;
  width: 100%;
  background-color: transparent;
}

textarea {
  height: 150px;
  color: #ffffff;
}

input[type="text"] {
  width: 230px;
  color: #ffffff;
}

input[type="email"] {
  width: 400px;
  color: #ffffff;
}

input[type="submit"] {
  background-color: #636363;
  border: none;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

input[type="submit"]:hover {
  background-color: #333;
  color: #fff;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #979999;
  box-shadow: 0 0 10px rgba(181, 182, 182, 0.5);
}

input[type="checkbox"] {
  margin-left: 60px;
  transform: scale(1.5); 
}

.LayoutRgpd{
  display: flex;
  align-items: baseline;
  margin-top: 0;
}

input::-webkit-input-placeholder {
  color: #888;
}

input::-moz-placeholder {
  color: #888;
}

input:-ms-input-placeholder {
  color: #888;
}

input::-ms-input-placeholder {
  color: #888;
}

::placeholder {
  color: #f2f2f2;
}

.error {
  color: #40d1eb;
  font-weight: bolder;
  margin-bottom: 0;
}

@media screen and (max-width: 1205px) {
  .contactPage{
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0;
}
}

@media screen and (max-width: 620px) {
  .contactPage{
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0;
}
}


  input[type="text"]::placeholder,input[type="email"]::placeholder  {
    text-align: center;
  }


@media screen and (max-width: 1205px) {
  input[type="text"] {
    width: 150px;
    margin-bottom: 10px;
  }
  input[type="email"] {
    width: 300px;
  }
  input[type="checkbox"] {
    margin: 0 10px 0 0;
  }
}

@media screen and (max-width: 620px) {
  input[type="text"]{
  width:145px;
}
}


@media screen and (max-width: 430px) {
  ::placeholder{
color:transparent;
}
}

@media screen and (min-width:620px) and (max-width: 1205px) {
  .LayoutRgpd {
    width:500px
  }
}

@media screen and (max-width: 620px) {
  input[type="checkbox"] {
    width: 40px;
    margin-right: 7px!important;
    vertical-align: top;
    margin-top: 6px!important;
  }

  .LayoutRgpd {
    width: 300px;
    display: inline-flex;
    align-items: flex-start;
    line-height: 1.5;
    padding-bottom: 20px;
  }
}

